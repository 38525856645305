"use client"

import { useState, useEffect, useCallback } from "react"

type ScreenSize = "mobile" | "tablet" | "desktop"

interface ResponsiveState {
  width: number
  height: number
  screenSize: ScreenSize
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
}

export function useResponsive(): ResponsiveState {
  // Define Tailwind CSS breakpoints
  const BREAKPOINTS = {
    sm: 640,
    md: 768,
    lg: 1024,
  }

  // Function to determine the screen size
  const getScreenSize = useCallback(
    (width: number): ScreenSize => {
      if (width < BREAKPOINTS.sm) {
        return "mobile"
      } else if (width < BREAKPOINTS.lg) {
        return "tablet"
      } else {
        return "desktop"
      }
    },

    [BREAKPOINTS.lg, BREAKPOINTS.sm]
  )

  // Initialize state with default values
  const [state, setState] = useState<ResponsiveState>(() => {
    const width = typeof window !== "undefined" ? window.innerWidth : 1024
    const height = typeof window !== "undefined" ? window.innerHeight : 768
    const screenSize = getScreenSize(width)
    return {
      width,
      height,
      screenSize,
      isMobile: screenSize === "mobile",
      isTablet: screenSize === "tablet",
      isDesktop: screenSize === "desktop",
    }
  })

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    const handleResize = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        const width = window.innerWidth
        const height = window.innerHeight
        const screenSize = getScreenSize(width)
        setState({
          width,
          height,
          screenSize,
          isMobile: screenSize === "mobile",
          isTablet: screenSize === "tablet",
          isDesktop: screenSize === "desktop",
        })
      }, 100)
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener("resize", handleResize)
    }
  }, [getScreenSize])

  return state
}
